@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-gradient: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.text-shadow-glow {
  text-shadow: 0 0 20px rgba(59, 130, 246, 0.3);
}

body {
  @apply font-sans text-gray-800 bg-black;
  font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-bold;
}

.container {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
}

/* Smooth scroll behavior */
html {
  scroll-behavior: smooth;
}

/* Gradient text utility class */
.gradient-text {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-pink-600;
}

/* Grid pattern background */
.grid-pattern {
  background-size: 50px 50px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

@keyframes scanline {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}